import React, { useState, useEffect } from 'react';
import Layout from '../components/LandingLayout';
import SEO from '../components/seo';

import { windowGlobal } from '../config';
import { WithTrans } from '../language/withTrans';
import AuthView from '../views/AuthView';
import ForgotPasswordView from '../views/ForgotPasswordView';
import EmailSentView from '../views/EmailSentView';
import metaTranslations from '../lib/utils';
import { getEventProviderInstance } from '../appEvents';
import { EventProviders } from '../constants/events';
import styled, { ThemeProvider } from 'styled-components';
import KineduLogo from 'components/Landing/KineduLogo/KineduLogo';
import signUpImage from 'images/landing/educators_devices.png';
import themeConfig from '../themes';
import { PROJECT_PATH } from '../config';

const View = styled.div``;

const Left = styled.div`
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: -1;
  ${({ theme }) => `
    background-color: ${theme?.colors?.primary};
    ${theme.breakPoints.tablet} {
      display: block;
    }
  `}
`;

const Right = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: -1;
  background-color: #fff;
`;

const Image = styled.img.attrs(() => ({
  src: signUpImage,
  alt: 'Sign Up',
}))`
  width: calc(100% + 0px);
  position: relative;
`;

const Navbar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 45px 20px;
  align-items: center;
`;

const Languages = styled.div`
  display: flex;
  margin-left: 5px;
`;

const ViewContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

const LeftSection = styled.div.attrs(() => ({
  className: 'LeftSection',
}))`
  width: 100%;
  align-items: center;
  flex: 1;
  display: none;
  padding: 20px;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      padding: 40px;
      width: 100%;
      display: block;
    }
  `}
`;

const RightSection = styled.div`
  width: 100%;
  align-items: center;
  padding: 0px;
  max-width: 407px;
  margin: 0 auto;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      // padding: 10px;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;

    }
  `}
`;

const LanguageButton = styled.a`
  margin: 1px;
  ${({ theme }) => `
    ${theme.breakPoints.largePhone} {
      margin: 3px;
    }
  `}
`;

const BtnCircle = styled.div`
  width: 26px;
  height: 26px;
  font-size: 1.2rem;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, selected }) => `
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.primary};
    ${selected ? `
      background-color: ${theme?.colors?.primary};
      color: #fff;
      font-weight: 600;
    ` : ''}

    ${theme.breakPoints.largePhone} {
      width: 34px;
      height: 34px;
      font-size: 12px;
    }
  `}
`;

const FormContainer = styled.div`
  padding: 20px;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      max-width: 410px;
      margin: 0px 80px;
      width: 100%;
    }
 `}
`;

const DefaultKineduLogo = styled(KineduLogo).attrs(() => ({
  variant: 'default',
}))`
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      display: none;
    }
  `}
`;

const WhiteKineduLogo = styled(KineduLogo).attrs(() => ({
  variant: 'white',
}))`
  width: 330px;
  height: 40px;
  display: none;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      display: block;
    }
  `}
`;

const IndexPage = ({ lang }) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);


  useEffect(() => {
    const ReactPixel = getEventProviderInstance(EventProviders.FB_PIXEL);

    if (ReactPixel) {
      ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    if(typeof window !== "undefined"){
      window.location?.assign(window.location.href.replace(window.origin, `${window.origin}/app`))
   }
  }, [])


  return (
    <ThemeProvider theme={themeConfig}>
      <Layout>
        <SEO
          title={metaTranslations[lang].title}
          description={metaTranslations[lang].description}
          meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
        />
        <View>
          <Left />
          <Right />
          <ViewContainer>
            <Navbar>
              <WhiteKineduLogo lang={lang} />
              <DefaultKineduLogo lang={lang} />
              <Languages>
                <LanguageButton href={`${PROJECT_PATH()}/es/signin`}>
                  <BtnCircle selected={lang === 'es'}>
                    ES
                  </BtnCircle>
                </LanguageButton>
                <LanguageButton href={`${PROJECT_PATH()}/signin`}>
                  <BtnCircle selected={lang === 'en'}>
                    EN
                  </BtnCircle>
                </LanguageButton>
                <LanguageButton href={`${PROJECT_PATH()}/pt/signin`}>
                  <BtnCircle selected={lang === 'pt'}>
                    PT
                  </BtnCircle>
                </LanguageButton>
              </Languages>
            </Navbar>
            <Row>
              <LeftSection>
                <Image />
              </LeftSection>
              <RightSection>
                <FormContainer>
                  <AuthView
                    isLogin
                    onForgotPassword={() => {}}
                  />
                </FormContainer>
              </RightSection>
            </Row>
          </ViewContainer>
        </View>
      </Layout>
    </ThemeProvider>
  );
};

export default WithTrans()(IndexPage);
